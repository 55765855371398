import ContactInfo from "../components/ContactInfo/ContactInfo";
import MenuBar from "../components/menuBar/MenuBar";
import ServiceAccordion from "../components/ServiceAccordion/ServiceAccordion";
import ServicesGallery from "../components/ServicesGallery/ServicesGallery";
import "./ProtoPage.css"
import protoImg from "../../assets/réalisationsipcb-3-710x375.jpg";
import {useTranslation} from "../global/translationsTools";
import {lang} from "./AntennaPage_lang";

function ProtoPage() {
    const translations = useTranslation(lang);

    const accordionData = [
        {
            title: translations('section1_1'),
            content: translations('section1_2'),
            contentHeight: 150,
        },
        {
            title: translations('section2_1'),
            content: translations('section2_2'),
            contentHeight: 200,
        },
        {
            title: translations('section3_1'),
            content: translations('section3_2'),
            contentHeight: 150,
        },
    ];

    return (
        <div>
            <MenuBar></MenuBar>
            <div className="proto-1">
                <div className="proto-1-title sice-font">{translations('title')}</div>
                <div className="proto-1-decoration"></div>
            </div>
            <div className="proto-2">
                <img className="proto-2-img" src={protoImg}></img>
                <div className="proto-2-info">
                    <div className="sice-font proto-2-info-1">{translations('heading')}</div>
                    <div className="sice-font proto-2-info-2">{translations('desc')}</div>
                    <ServiceAccordion accordionData={accordionData}></ServiceAccordion>
                </div>
            </div>
            <div className="proto-3">
                <div className="proto-3-title sice-font">{translations('services')}</div>
                <div className="proto-3-decoration"></div>
                <ServicesGallery imgWidth="20vw"></ServicesGallery>
            </div>
            <ContactInfo></ContactInfo>
        </div>
    );
}

export default ProtoPage