import "./AttributeSection.css"

function AttributeSection(props: any) {
    return (
        <div className='att-section'>
            <div className='att-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 -960 960 960" width="64px" fill="white"><path d={props.path}/></svg>
            </div>
            <div className='att-text'>
                <div className='att-title custom-font'>{props.title}</div>
                <div className='att-info custom-font'>{props.info}</div>
            </div>
        </div>
    );
}

export default AttributeSection;