export const lang = {
    en: {
        title: "Strategic consulting",
        heading: "EXPERTS AT YOUR SERVICE",
        desc: "From initial design reviews to simple consultations, we support you from the very beginning of your projects, seamlessly integrating with your organization.",
        section1_1: "Design for manufacturing",
        section1_2: "Optimizing designs to enable efficient and cost-effective manufacturing.",
        section2_1: "Design for test",
        section2_2: "We develop custom test jigs to ensure reliable and efficient testing of your products.",
        section3_1: "Design for cost",
        section3_2: "We optimize component selection for efficiency, availability, and equivalency, while negotiating the best prices with distributors and manufacturers.",
        section4_1: "Proof of concept",
        section4_2: "Interested in learning more? Contact us for details!",
        section5_1: "manufacturing strategies",
        section5_2: "Offering both local and offshore production options to meet your needs.",
        section6_1: "product life cycle",
        section6_2: "Time to Market – Cost to Market: Balancing speed and cost efficiency throughout your product’s life cycle.",
        section7_1: "design review",
        section7_2: "Want to learn more? Contact us for further details!",
        section8: "ALL SERVICES",
    },
    fr: {
        title: "Consultation stratégique",
        heading: "EXPERTS À VOTRE SERVICE",
        desc: "Des revues de conception initiales aux simples consultations, nous vous soutenons dès le début de vos projets, en nous intégrant parfaitement à votre organisation.",
        section1_1: "Conception pour la fabrication",
        section1_2: "Optimisation des conceptions pour permettre une fabrication efficace et rentable.",
        section2_1: "Conception pour les tests",
        section2_2: "Nous développons des gabarits de test sur mesure pour garantir des tests fiables et efficaces de vos produits.",
        section3_1: "Conception pour le coût",
        section3_2: "Nous optimisons la sélection des composants pour l'efficacité, la disponibilité et l'équivalence, tout en négociant les meilleurs prix avec les distributeurs et les fabricants.",
        section4_1: "Preuve de concept",
        section4_2: "Vous souhaitez en savoir plus ? Contactez-nous pour plus de détails !",
        section5_1: "stratégies de fabrication",
        section5_2: "Offrant des options de production locales et offshore pour répondre à vos besoins.",
        section6_1: "cycle de vie du produit",
        section6_2: "Délai de mise sur le marché – Coût de mise sur le marché : Équilibrer rapidité et rentabilité tout au long du cycle de vie de votre produit.",
        section7_1: "revue de conception",
        section7_2: "Vous souhaitez en savoir plus ? Contactez-nous pour plus de détails !",
        section8: "TOUS NOS SERVICES",
    }
}