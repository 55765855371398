export const lang = {
    en: {
        title1: "CUSTOMER SERVICE & SALES",
        title2: "ADDRESS",
        desc: "SOLUTION I.C.E. meets your goals and elevates your projects to the highest standards of quality and production efficiency.",
    },
    fr: {
        title1: "SERVICE CLIENT & VENTES",
        title2: "ADRESSE",
        desc: "SOLUTION I.C.E. atteint vos objectifs et élève vos projets aux plus hauts standards de qualité et d'efficacité de production.",
    }
}