import MenuBar from "../components/menuBar/MenuBar";
import "./ContactPage.css"
import ContactInfo from "../components/ContactInfo/ContactInfo";
import contactImg from '../../assets/pcb-systems-1500x1000.jpg';
import {useTranslation} from "../global/translationsTools";
import {lang} from "./ContactPage_lang";

function ContactPage() {

    const translations = useTranslation(lang);

    return (
        <div className="">
            <MenuBar></MenuBar>
            <iframe
                className={"cont-img"}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22436.99737200389!2d-72.56704772450989!3d45.33620109266586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc9d7d080d613ef%3A0x79382d0f85b8556f!2sWaterloo%2C%20QC!5e0!3m2!1sen!2sca!4v1726874214313!5m2!1sen!2sca"
                width="600" allowFullScreen={true} loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
            <div className="cont-info-box cont-section1">
                <div className="cont-section1-2">
                    <div className="cont-section1-2-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px"
                            fill="#FFFFFF">
                            <path
                                d="M360-400v-160q0-17 11.5-28.5T400-600h160q17 0 28.5 11.5T600-560v160q0 17-11.5 28.5T560-360H400q-17 0-28.5-11.5T360-400Zm0 240v-40h-80q-33 0-56.5-23.5T200-280v-80h-40q-17 0-28.5-11.5T120-400q0-17 11.5-28.5T160-440h40v-80h-40q-17 0-28.5-11.5T120-560q0-17 11.5-28.5T160-600h40v-80q0-33 23.5-56.5T280-760h80v-40q0-17 11.5-28.5T400-840q17 0 28.5 11.5T440-800v40h80v-40q0-17 11.5-28.5T560-840q17 0 28.5 11.5T600-800v40h80q33 0 56.5 23.5T760-680v80h40q17 0 28.5 11.5T840-560q0 17-11.5 28.5T800-520h-40v80h40q17 0 28.5 11.5T840-400q0 17-11.5 28.5T800-360h-40v80q0 33-23.5 56.5T680-200h-80v40q0 17-11.5 28.5T560-120q-17 0-28.5-11.5T520-160v-40h-80v40q0 17-11.5 28.5T400-120q-17 0-28.5-11.5T360-160Zm320-120v-400H280v400h400Z" />
                        </svg>
                    </div>
                    <div className="cont-section1-2-title sice-font">Solution I.C.E.</div>
                    <div className="cont-section1-2-desc sice-font">{translations('section1')}</div>
                </div>
            </div>
            <div className="cont-info-box cont-section2">
                <div className={"cont-section2-layout"}>
                    <div className="cont-section2-title sice-font">Information</div>
                    <div className={"cont-section2-decoration"}></div>
                </div>
                <div className={"sice-font cont-section2-sm-font"}>{translations('info')}</div>
                <div className={"cont-section2-layout"}>
                    <div className="cont-section2-title sice-font">{translations('contactus')}</div>
                    <div className={"cont-section2-decoration"}></div>
                </div>
                <div className={"cont-email sice-font"}>E-Mail:
                    <a href="mailto:info@solution-ice.com"
                        className={"sice-font cont-section2-sm-font"}>info@solution-ice.com</a>
                </div>
            </div>
            <ContactInfo></ContactInfo>
        </div>
    );
}

export default ContactPage;