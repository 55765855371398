import MenuBar from "../components/menuBar/MenuBar";
import "./RealisationsPage.css"
import realisationsCover from "../../assets/pcb-systems-1500x1000.jpg";
import ContactInfo from "../components/ContactInfo/ContactInfo";
import RealisationsGallery from "../components/RealisationsGallery/RealisationsGallery";
import {useTranslation} from "../global/translationsTools";
import {lang} from "./RealisationsPage_lang";

function RealisationsPage() {
    const translations = useTranslation(lang);

    return (
        <div>
            <MenuBar></MenuBar>
            <img src={realisationsCover} className="realisations-cover"></img>
            <div className="section1 info-box">
                <div className="section1-1">
                    <div className="section1-title">{translations('section1_1')}</div>
                    <div className="section1-decoration"></div>
                </div>
                <div className="section1-2-1">{translations('section1_2')}</div>
            </div>
            <div className="section2">
                <RealisationsGallery></RealisationsGallery>
            </div>
            <ContactInfo></ContactInfo>
        </div>
    );
}

export default RealisationsPage;