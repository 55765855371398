export const lang = {
    en: {
        strat: "Strategic consulting",
        prod: "Products development",
        antenna: "Antenna integration",
    },
    fr: {
        strat: "Consultation stratégique",
        prod: "Développement de produits",
        antenna: "Intégration d'antennes",
    }
}