export const lang = {
    en: {
        prod1_1: "fleet control",
        prod1_2: "GPS tracking tools",
        prod2_1: "gps unit for telecom",
        prod2_2: "GPS and two-way voice communication",
        prod3_1: "Tracking units in linux",
        prod3_2: "ARM 9 in Linux",
        prod4_1: "Railway",
        prod4_2: "Acquisition system",
        prod5_1: "Optical unit",
        prod5_2: "Imaging unit by light reconstruction",
        prod6_1: "Orthopedic medical equipment",
        prod6_2: "Weight distribution captive sensor"
    },
    fr: {
        prod1_1: "Contrôle de flotte",
        prod1_2: "Outils de suivi GPS",
        prod2_1: "Unité GPS pour télécommunications",
        prod2_2: "GPS et communication vocale bidirectionnelle",
        prod3_1: "Unités de suivi sous Linux",
        prod3_2: "ARM 9 sous Linux",
        prod4_1: "Ferroviaire",
        prod4_2: "Système d'acquisition",
        prod5_1: "Unité optique",
        prod5_2: "Unité d'imagerie par reconstruction de lumière",
        prod6_1: "Équipement médical orthopédique",
        prod6_2: "Capteur de répartition de poids captif"
    }
}