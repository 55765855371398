export const lang = {
    en: {
        section1: "MAKE YOUR PROJECTS BECOME REALITY FROM ELECTRONIC DESIGN TO TURNKEY PRODUCTION OF YOUR PROJECTS",
        info: "Telephone: 450.577.3906",
        contactus: "Contact Us",
    },
    fr: {
        section1: "FAITES DE VOS PROJETS UNE RÉALITÉ, DE LA CONCEPTION ÉLECTRONIQUE À LA PRODUCTION CLÉ EN MAIN DE VOS PROJETS",
        info: "Téléphone: 450.577.3906",
        contactus: "Contactez-nous",
    }
}