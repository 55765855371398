export const translations = {
    en: {
        services: "Services",
        productDevelopment: "Product Development",
        antennaIntegration: "Antenna Integration",
        strategicConsulting: "Strategic Consulting",
        realisations: "Realisations",
        contact: "Contact",
        languageToggle: "Français"
    },
    fr : {
        services: "Services",
        productDevelopment: "Développement de produits",
        antennaIntegration: "Intégration d'antennes",
        strategicConsulting: "Consultation stratégique",
        realisations: "Réalisations",
        contact: "Contact",
        languageToggle: "English"
    }
}