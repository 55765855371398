export const lang = {
    en: {
        title: "Product Development",
        heading: "Your Needs, Our Mission",
        desc1: "We specialize in developing innovative products across a wide range of industries.",
        desc2: "Constantly exploring new technologies, we focus on combining high performance, cutting-edge innovation, and manufacturing efficiency.",
        section1_1: "Electronic design",
        section1_2: "Electronic schematics, PCB layout, embedded programming, software design, hardware and software for testing procedures, and tailored bench testing.",
        section2_1: "interactive design",
        section2_2: "Graphic User Interfaces and Graphic Design tailored to enhance user experience.",
        section3_1: "hardware design",
        section3_2: "Schematics & PCB development, 3D component modeling, design for manufacturing, component selection, and integration of cutting-edge technologies to ensure optimal performance and reliability.",
        section4_1: "software design",
        section4_2: "User Interface development, configurator tools, custom software solutions, and seamless integration with hardware for a complete, user-friendly experience.",
        section5_1: "embedded software design",
        section5_2: "Expertise in ARM processors (Cortex M3, Cortex M4, ARM9 & DSP), FreeRTOS, Micrium OS, ZephyrOS, Embedded Linux, real-time systems, wireless communications, low-level hardware interfaces, and driver development (board support packages).",
        services: "ALL SERVICES",
    },
    fr: {
        title: "Développement de produits",
        heading: "Vos besoins, notre mission",
        desc1: "Nous sommes spécialisés dans le développement de produits innovants dans une large gamme d'industries.",
        desc2: "En explorant constamment de nouvelles technologies, nous nous concentrons sur la combinaison de haute performance, d'innovation de pointe et d'efficacité de fabrication.",
        section1_1: "Conception électronique",
        section1_2: "Schémas électroniques, conception de circuits imprimés (PCB), programmation embarquée, conception de logiciels, matériel et logiciels pour procédures de test, et tests en laboratoire sur mesure.",
        section2_1: "Conception interactive",
        section2_2: "Interfaces graphiques et design graphique conçus pour améliorer l'expérience utilisateur.",
        section3_1: "Conception matérielle",
        section3_2: "Développement de schémas et de PCB, modélisation 3D des composants, conception pour la fabrication, sélection des composants et intégration de technologies de pointe pour garantir des performances et une fiabilité optimales.",
        section4_1: "Conception logicielle",
        section4_2: "Développement d'interface utilisateur, outils de configuration, solutions logicielles sur mesure et intégration transparente avec le matériel pour une expérience complète et conviviale.",
        section5_1: "Conception de logiciels embarqués",
        section5_2: "Expertise en processeurs ARM (Cortex M3, Cortex M4, ARM9 & DSP), FreeRTOS, Micrium OS, ZephyrOS, Linux embarqué, systèmes en temps réel, communications sans fil, interfaces matérielles de bas niveau et développement de pilotes (paquets de support de carte).",
        services: "TOUS NOS SERVICES"
    }
}