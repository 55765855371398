import ContactInfo from "../components/ContactInfo/ContactInfo";
import MenuBar from "../components/menuBar/MenuBar";
import "./ServicesPage.css"
import rubanImg from "../../assets/ruban-ipcb-systems1-1400x430.jpg"
import ServicesGallery from "../components/ServicesGallery/ServicesGallery";
import {useTranslation} from "../global/translationsTools";
import {lang} from "./ServicesPage_lang";

function ServicesPage() {
    const translations = useTranslation(lang);

    return (
        <div>
            <MenuBar></MenuBar>
            <img src={rubanImg} className="ruban-img"></img>
            <div className="serv-info-box serv-section1">
                <div className="serv-section1-1">
                    <div className="serv-section1-title sice-font">{translations('section1')}</div>
                    <div className="serv-section1-decoration"></div>
                </div>
                <div className="serv-section1-2">
                    <div className="serv-section1-2-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill="#FFFFFF"><path d="M113.33-120q-14.16 0-23.75-9.58Q80-139.17 80-153.33v-413.34q0-14.16 9.58-23.75 9.59-9.58 23.75-9.58H260q14.17 0 23.75 9.58 9.58 9.59 9.58 23.75v413.34q0 14.16-9.58 23.75Q274.17-120 260-120H113.33Zm293.34 0q-14.17 0-23.75-9.58-9.59-9.59-9.59-23.75v-653.34q0-14.16 9.59-23.75 9.58-9.58 23.75-9.58h146.66q14.17 0 23.75 9.58 9.59 9.59 9.59 23.75v653.34q0 14.16-9.59 23.75-9.58 9.58-23.75 9.58H406.67ZM700-120q-14.17 0-23.75-9.58-9.58-9.59-9.58-23.75v-333.34q0-14.16 9.58-23.75Q685.83-520 700-520h146.67q14.16 0 23.75 9.58 9.58 9.59 9.58 23.75v333.34q0 14.16-9.58 23.75-9.59 9.58-23.75 9.58H700Z" /></svg>
                    </div>
                    <div className="serv-section1-2-text sice-font">{translations('section1')}</div>
                </div>
            </div>
            <div className="serv-info-box serv-section2">
                <ServicesGallery imgWidth={"33.33vw"}></ServicesGallery>
            </div>
            <ContactInfo></ContactInfo>
        </div>
    );
}

export default ServicesPage;