import {useContext} from "react";
import {LanguageContext} from "../Router/Router";

export const useTranslation = (translations: any) => {
    const context = useContext(LanguageContext);

    if (!context) {
        throw new Error("useTranslation must be used within a LanguageContext.Provider");
    }

    const { isEnglish } = context;

    const currentLanguage = isEnglish ? 'en' : 'fr';

    return (key: keyof typeof translations['en']) => translations[currentLanguage][key];
};
