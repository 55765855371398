import ContactInfo from "../components/ContactInfo/ContactInfo";
import MenuBar from "../components/menuBar/MenuBar";
import "./ProductDevPage.css"
import prodevImg from "../../assets/pcb-creation-495x400.jpg";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import ServiceAccordion from "../components/ServiceAccordion/ServiceAccordion";
import ServicesGallery from "../components/ServicesGallery/ServicesGallery";
import {useTranslation} from "../global/translationsTools";
import {lang} from "./ProductDevPage_lang";

function ProductDevPage() {
    const translations = useTranslation(lang);

    const accordionData = [
        {
            title: translations('section1_1'),
            content: translations('section1_2'),
            contentHeight: 110,
        },
        {
            title: translations('section2_1'),
            content: translations('section2_2'),
            contentHeight: 90,
        },
        {
            title: translations('section3_1'),
            content: translations('section3_2'),
            contentHeight: 150,
        },
        {
            title: translations('section4_1'),
            content: translations('section4_2'),
            contentHeight: 150,
        },
        {
            title: translations('section5_1'),
            content: translations('section5_2'),
            contentHeight: 200,
        },
    ];

    return (
        <div>
            <MenuBar></MenuBar>
            <div className={"page-base"}>
                <div className="prodev-1">
                    <div className="prodev-1-title sice-font">{translations('title')}</div>
                    <div className="prodev-1-decoration"></div>
                </div>
                <div className="prodev-2">
                    <img className="prodev-2-img" src={prodevImg}></img>
                    <div className="prodev-2-info">
                        <div className="sice-font prodev-2-info-1">{translations('heading')}</div>
                        <div className="sice-font prodev-2-info-2">{translations('desc1')}</div>
                        <div className="sice-font prodev-2-info-2">{translations('desc2')}</div>
                        <ServiceAccordion accordionData={accordionData}></ServiceAccordion>
                    </div>
                </div>
                <div className="prodev-3">
                    <div className="prodev-3-title sice-font">{translations('services')}</div>
                    <div className="prodev-3-decoration"></div>
                    <ServicesGallery imgWidth="20vw"></ServicesGallery>
                </div>
            </div>
            <ContactInfo></ContactInfo>
        </div>
    );
}

export default ProductDevPage;