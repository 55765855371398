import "./ServicesGallery.css"
import section3Img1 from "../../../assets/mains-au-travail-495x400.jpg";
import section3Img2 from "../../../assets/pcb-creation-495x400.jpg";
import section3Img3 from "../../../assets/réalisationsipcb-3-710x375.jpg";
import { Link } from "react-router-dom";
import {useTranslation} from "../../global/translationsTools";
import {lang} from "./ServicesGallery_lang";

function ServicesGallery({ imgWidth }: { imgWidth: string }) {
    const aspectRatio = 1.237;

    const imgHeight = `calc(${imgWidth} / ${aspectRatio})`;
    const topOffset = `calc(calc(-1 * calc(${imgHeight})) - 4px)`;
    const containerHeight = `calc(${imgHeight} + 72px)`;

    const translations = useTranslation(lang);

    return (
        <div className='sg-section3'>
            <div className='sg-section3-container' style={{ height: containerHeight }}>
                <div className="sg-section3-img" style={{ width: imgWidth, height: imgHeight }}>
                    <img src={section3Img1} style={{ width: imgWidth, height: imgHeight }} />
                    <Link to="/strategic-consulting" className="sg-img-filter" style={{ width: imgWidth, height: imgHeight, top: topOffset }}>
                        <div className="sg-img-filter-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="rgba(250, 250, 250, 0.75)"><path d="M767-520 612-675q-12-12-12-28.5t12-28.5q12-11 28.5-11t27.5 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L668-308q-12 12-28 11.5T612-308q-12-12-12.5-28t11.5-28l156-156Zm-240 40H280q-50 0-85 35t-35 85v120q0 17-11.5 28.5T120-200q-17 0-28.5-11.5T80-240v-120q0-83 58.5-141.5T280-560h247L412-675q-12-12-12-28.5t12-28.5q12-11 28.5-11t27.5 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L468-308q-12 12-28 11.5T412-308q-12-12-12.5-28t11.5-28l116-116Z" /></svg>
                        </div>
                    </Link>
                </div>
                <div className='sg-section3-title'>{translations('strat')}</div>
            </div>
            <div className='sg-section3-container'>
                <div className="sg-section3-img" style={{ width: imgWidth, height: imgHeight }}>
                    <img src={section3Img2} style={{ width: imgWidth, height: imgHeight }} />
                    <Link to="/product-development" className="sg-img-filter" style={{ width: imgWidth, height: imgHeight, top: topOffset }}>
                        <div className="sg-img-filter-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="rgba(250, 250, 250, 0.75)"><path d="M767-520 612-675q-12-12-12-28.5t12-28.5q12-11 28.5-11t27.5 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L668-308q-12 12-28 11.5T612-308q-12-12-12.5-28t11.5-28l156-156Zm-240 40H280q-50 0-85 35t-35 85v120q0 17-11.5 28.5T120-200q-17 0-28.5-11.5T80-240v-120q0-83 58.5-141.5T280-560h247L412-675q-12-12-12-28.5t12-28.5q12-11 28.5-11t27.5 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L468-308q-12 12-28 11.5T412-308q-12-12-12.5-28t11.5-28l116-116Z" /></svg>
                        </div>
                    </Link>
                </div>
                <div className='sg-section3-title'>{translations('prod')}</div>
            </div>
            <div className='sg-section3-container'>
                <div className="sg-section3-img" style={{ width: imgWidth, height: imgHeight }}>
                    <img src={section3Img3} style={{ width: imgWidth, height: imgHeight, objectFit: 'cover' }} />
                    <Link to="/antenna-integration" className="sg-img-filter" style={{ width: imgWidth, height: imgHeight, top: topOffset }}>
                        <div className="sg-img-filter-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="rgba(250, 250, 250, 0.75)"><path d="M767-520 612-675q-12-12-12-28.5t12-28.5q12-11 28.5-11t27.5 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L668-308q-12 12-28 11.5T612-308q-12-12-12.5-28t11.5-28l156-156Zm-240 40H280q-50 0-85 35t-35 85v120q0 17-11.5 28.5T120-200q-17 0-28.5-11.5T80-240v-120q0-83 58.5-141.5T280-560h247L412-675q-12-12-12-28.5t12-28.5q12-11 28.5-11t27.5 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L468-308q-12 12-28 11.5T412-308q-12-12-12.5-28t11.5-28l116-116Z" /></svg>
                        </div>
                    </Link>
                </div>
                <div className='sg-section3-title'>{translations('antenna')}</div>
            </div>
        </div>
    );
}

export default ServicesGallery;