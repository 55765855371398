import { BrowserRouter, Routes, Route } from "react-router-dom";
import ServicesPage from "../ServicesPage/ServicesPage";
import RealisationsPage from "../RealisationsPage/RealisationsPage";
import MenuBar from "../components/menuBar/MenuBar";
import HomePage from "../HomePage/HomePage";
import ProductDevPage from "../ProductDevPage/ProductDevPage";
import ProtoPage from "../ProtoPage/ProtoPage";
import StratPage from "../StratPage/StratPage";
import ScrollToTop from "../ScrollToTop";
import ContactPage from "../ContactPage/ContactPage";
import {createContext, useState} from "react";

interface LanguageContextType {
    isEnglish: boolean;
    setIsEnglish: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

function App() {
    const [isEnglish, setIsEnglish] = useState(true);

    return (
        <LanguageContext.Provider value={{ isEnglish, setIsEnglish }}>
            {/* <MenuBar /> */}
            <ScrollToTop></ScrollToTop>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="services" element={<ServicesPage />}>
                </Route>
                <Route path="product-development" element={<ProductDevPage />} />
                <Route path="antenna-integration" element={<ProtoPage />} />
                <Route path="strategic-consulting" element={<StratPage />} />
                <Route path="contact" element={<ContactPage />} />
                <Route path="realisations" element={<RealisationsPage />} />
            </Routes>
        </LanguageContext.Provider>
    );
}

export default function AppRouter() {
    return (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    );
}
