export const lang = {
    en: {
        title: "Antenna integration",
        heading: "RF INTEGRATION STRATEGY",
        desc: "Our staff can study with you the most effective and efficient manufacturing solutions in terms of cost and reliability, even before beginning development of your products.",
        section1_1: "Antenna debugging",
        section1_2: "Our team of RF troubleshooting experts is committed to delivering fast, reliable solutions that respect your time-to-market deadlines. We understand the importance of your project timelines and provide tailored support to ensure seamless RF performance and quick problem resolution.",
        section2_1: "rf support",
        section2_2: "Every detail of RF design is meticulously addressed, and our deep understanding of manufacturing processes ensures consistency, efficiency, and cost reduction. All our product development proposals are offered as package deals—no hidden fees or billing surprises. We maintain complete transparency and collaboration with our customers at every stage.",
        section3_1: "free antenna tuning",
        section3_2: "When you choose our integration services utilizing partner technology, antenna tuning is included at no extra cost. This ensures optimal performance while making your product development process smoother and more cost-effective.",
        services: "ALL SERVICES",
    },
    fr: {
        title: "Intégration d'antenne",
        heading: "STRATÉGIE D'INTÉGRATION RF",
        desc: "Notre personnel peut étudier avec vous les solutions de fabrication les plus efficaces et efficientes en termes de coût et de fiabilité, même avant de commencer le développement de vos produits.",
        section1_1: "Débogage d'antenne",
        section1_2: "Notre équipe d'experts en dépannage RF s'engage à fournir des solutions rapides et fiables qui respectent vos délais de mise sur le marché. Nous comprenons l'importance de vos délais de projet et offrons un soutien sur mesure pour garantir des performances RF sans faille et une résolution rapide des problèmes.",
        section2_1: "Soutien RF",
        section2_2: "Chaque détail de la conception RF est minutieusement pris en compte, et notre compréhension approfondie des processus de fabrication garantit cohérence, efficacité et réduction des coûts. Toutes nos propositions de développement de produits sont offertes sous forme de forfaits—sans frais cachés ni surprises de facturation. Nous maintenons une transparence et une collaboration complètes avec nos clients à chaque étape.",
        section3_1: "Ajustement d'antenne gratuit",
        section3_2: "Lorsque vous choisissez nos services d'intégration utilisant la technologie de nos partenaires, l'ajustement de l'antenne est inclus sans frais supplémentaires. Cela garantit des performances optimales tout en rendant votre processus de développement de produit plus fluide et plus rentable.",
        services: "TOUS NOS SERVICES"
    }
}