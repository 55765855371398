import React, { useEffect, useState } from "react";
import "./RealisationsGallery.css";
import rel1 from "../../../assets/realisations/réalisationsipcb-21-710x375.jpg";
import rel2 from "../../../assets/realisations/réalisationsipcb-3-710x375.jpg";
import rel3 from "../../../assets/realisations/réalisationsipcb-4-710x375.jpg";
import rel4 from "../../../assets/realisations/réalisationsipcb-7-710x375.jpg";
import rel5 from "../../../assets/realisations/réalisationsipcb-ca-mijote-710x375.jpg";
import rel6 from "../../../assets/realisations/réalisationsipcb1-710x375.jpg";

import {useTranslation} from "../../global/translationsTools";
import {lang} from "./RealisationsGallery_lang";

function RealisationsGallery() {
    const [slideIndex, setSlideIndex] = useState(1);
    const translations = useTranslation(lang);

    useEffect(() => {
        showDivs(slideIndex);
    }, [slideIndex]);

    function plusDiv(index: number) {
        setSlideIndex((prevIndex) => prevIndex + index);
    }

    function showDivs(n: number) {
        const slides = document.getElementsByClassName("mySlides");
        if (slides.length === 0) return;

        if (n > slides.length) {
            setSlideIndex(1);
            return;
        }
        if (n < 1) {
            setSlideIndex(slides.length);
            return;
        }

        for (let i = 0; i < slides.length; i++) {
            slides[i].classList.remove("activeSlide");
        }

        slides[n - 1].classList.add("activeSlide");
    }

    return (
        <div className="gallery-container">
            <div className="slides-wrapper">
                <div className="mySlides activeSlide">
                    <img src={rel1} alt="slide 1" />
                    <div className="slide-info">
                        <div className="slide-info-title">{translations('prod1_1')}</div>
                        <div className="slide-info-description">{translations('prod1_2')}</div>
                    </div>
                </div>
                <div className="mySlides activeSlide">
                    <img src={rel2} alt="slide 1" />
                    <div className="slide-info">
                        <div className="slide-info-title">{translations('prod2_1')}</div>
                        <div className="slide-info-description">{translations('prod2_2')}</div>
                    </div>
                </div>
                <div className="mySlides activeSlide">
                    <img src={rel3} alt="slide 1" />
                    <div className="slide-info">
                        <div className="slide-info-title">{translations('prod3_1')}</div>
                        <div className="slide-info-description">{translations('prod3_2')}</div>
                    </div>
                </div>
                <div className="mySlides activeSlide">
                    <img src={rel4} alt="slide 1" />
                    <div className="slide-info">
                        <div className="slide-info-title">{translations('prod4_1')}</div>
                        <div className="slide-info-description">{translations('prod4_2')}</div>
                    </div>
                </div>
                <div className="mySlides activeSlide">
                    <img src={rel5} alt="slide 1" />
                    <div className="slide-info">
                        <div className="slide-info-title">{translations('prod5_1')}</div>
                        <div className="slide-info-description">{translations('prod5_2')}</div>
                    </div>
                </div>
                <div className="mySlides activeSlide">
                    <img src={rel6} alt="slide 1" />
                    <div className="slide-info">
                        <div className="slide-info-title">{translations('prod6_1')}</div>
                        <div className="slide-info-description">{translations('prod6_2')}</div>
                    </div>
                </div>
            </div>
            <div className="controls">
                <div onClick={() => plusDiv(-1)} style={{ cursor: 'pointer' }}><svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 -960 960 960" width="36px" fill="#e8eaed"><path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" /></svg></div>
                <div onClick={() => plusDiv(1)} style={{ cursor: 'pointer' }}><svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 -960 960 960" width="36px" fill="white"><path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" /></svg></div>
            </div>
        </div>
    );
}

export default RealisationsGallery;
