import ContactInfo from "../components/ContactInfo/ContactInfo";
import MenuBar from "../components/menuBar/MenuBar";
import ServiceAccordion from "../components/ServiceAccordion/ServiceAccordion";
import ServicesGallery from "../components/ServicesGallery/ServicesGallery";
import "./StratPage.css";
import stratImg from "../../assets/mains-au-travail-495x400.jpg";
import {useTranslation} from "../global/translationsTools";
import {lang} from "./StratPage_lang";

function StratPage() {
    const translations = useTranslation(lang);

    const accordionData = [
        {
            title: translations('section1_1'),
            content: translations('section1_2'),
            contentHeight: 110,
        },
        {
            title: translations('section2_1'),
            content: translations('section2_2'),
            contentHeight: 150,
        },
        {
            title: translations('section3_1'),
            content: translations('section3_2'),
            contentHeight: 150,
        },
        {
            title: translations('section4_1'),
            content: translations('section4_2'),
            contentHeight: 150,
        },
        {
            title: translations('section5_1'),
            content: translations('section5_2'),
            contentHeight: 150,
        },
        {
            title: translations('section6_1'),
            content: translations('section6_2'),
            contentHeight: 150,
        },
        {
            title: translations('section7_1'),
            content: translations('section7_2'),
            contentHeight: 150,
        },
    ];

    return (
        <div>
            <MenuBar></MenuBar>
            <div className="strat-1">
                <div className="strat-1-title sice-font">{translations('title')}</div>
                <div className="strat-1-decoration"></div>
            </div>
            <div className="strat-2">
                <img className="strat-2-img" src={stratImg}></img>
                <div className="strat-2-info">
                    <div className="sice-font strat-2-info-1">{translations('heading')}</div>
                    <div className="sice-font strat-2-info-2">{translations('desc')}</div>
                    <ServiceAccordion accordionData={accordionData}></ServiceAccordion>
                </div>
            </div>
            <div className="strat-3">
                <div className="strat-3-title sice-font">{translations('section8')}</div>
                <div className="strat-3-decoration"></div>
                <ServicesGallery imgWidth="20vw"></ServicesGallery>
            </div>
            <ContactInfo></ContactInfo>
        </div>
    );
}

export default StratPage