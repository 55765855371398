import { useState } from "react";
import "./ServiceAccordion.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

interface AccordionItem {
    title: string;
    content: string;
    contentHeight: number;
}

interface ServiceAccordionProps {
    accordionData: AccordionItem[];
}

function ServiceAccordion({ accordionData }: ServiceAccordionProps) {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const toggleAccordion = (index: number) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className="accordion-container">
            {accordionData.map((item, index) => (
                <div key={index} className={activeIndex === index ? 'active' : ''}>
                    <button
                        className="accordion sice-font"
                        onClick={() => toggleAccordion(index)}
                    >
                        {item.title}
                        <span className="icon">
                            <FontAwesomeIcon icon={activeIndex === index ? faMinus : faPlus} />
                        </span>
                    </button>
                    <div
                        className="panel sice-font"
                        style={{
                            maxHeight: activeIndex === index ? `${item.contentHeight}px` : '0',
                        }}
                    >
                        <p>{item.content}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ServiceAccordion;
