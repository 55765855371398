import {Link, Outlet} from 'react-router-dom';
import logo from '../../../assets/Logo-solution-ICE.jpg';
import "./MenuBar.css"
import {useContext, useState} from 'react';
import {LanguageContext} from "../../Router/Router";
import { translations } from './MenuBar_lang';

export const useTranslation = () => {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error("useTranslation must be used within a LanguageContext.Provider");
  }

  const { isEnglish } = context;

  const currentLanguage = isEnglish ? 'en' : 'fr';

  return (key: keyof typeof translations['en']) => translations[currentLanguage][key];
};


function MenuBar() {
  const [isSubnavVisible, setSubnavVisible] = useState(false);
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error("MenuBar must be used within a LanguageContext.Provider");
  }

  const { isEnglish, setIsEnglish } = context;

  const showSubnav = () => setSubnavVisible(true);
  const hideSubnav = () => setSubnavVisible(false);

  const translations = useTranslation();

  return (
    <div>
      <header className='menu-bar'>
        <Link to="/" className='logo'><img src={logo} className='logo'></img></Link>
        <div className='menu-buttons'>
          <Link
            onMouseEnter={showSubnav}
            onMouseLeave={hideSubnav}
            className='subnavbtn menu-button sice-font' to="/services">
            Services
          </Link>
          <div
            className={`subnav-content ${isSubnavVisible ? 'open' : 'closed'}`}
            onMouseEnter={showSubnav}
            onMouseLeave={hideSubnav}
          >
            <Link className='subnav-btn sice-font' to="/product-development">{translations('productDevelopment')}</Link>
            <Link className='subnav-btn sice-font' to="/antenna-integration">{translations('antennaIntegration')}</Link>
            <Link className='subnav-btn sice-font' to="/strategic-consulting">{translations('strategicConsulting')}</Link>
          </div>
          <Link className='menu-button sice-font' to="/realisations">{translations('realisations')}</Link>
          <Link className='menu-button sice-font' to="/contact">Contact</Link>
          <div className='menu-button sice-font' onClick={() => setIsEnglish(!isEnglish)}>{translations('languageToggle')}</div>
        </div>
      </header>
      <Outlet/>
    </div>
  );
}

export default MenuBar;