export const lang = {
    en: {
        section1: "Solution I.C.E. meets your goals and elevates your projects to the highest standards of quality and production efficiency.",
        section2: "HARDWARE – SOFTWARE – MECHANICAL DESIGN: comprehensive turnkey solutions",
        section3_1: "SOLUTION I.C.E. - WHO ARE WE?",
        section3_2: "At Solution I.C.E., our capacity for innovation and adaptability allows us to deliver solutions tailored to your specific needs. Our deep knowledge across diverse hardware and software domains enables us to offer expertise in a wide range of products and services.",
        section3_3: "Long-lasting business relationships",
        section3_4: "We prioritize long-term partnerships, striving to build lasting and harmonious relationships with our customers. Our focus is on creating value and trust that extends beyond immediate projects.\n",
        section3_5: "Win-win partnership",
        section3_6: "For our entire team, fostering an honest business environment built on trust is essential. We believe in creating genuine win-win partnerships that benefit both sides.",
        section3_7: "Reliability guaranteed",
        section3_8: "We take pride in honoring our commitments. We guarantee timely delivery and ensure that all objectives agreed upon with our customers are met with precision.",
        section3_9: "Meeting the competition",
        section3_10: "We follow a policy of fully transparent, fair pricing. Our services are highly competitive, ensuring value for our customers while maintaining profitability.",
        section3_11: "The staff of SOLUTION I.C.E., ideas that go a long way.",
        section3_12: "Contact Solution I.C.E.",
        section4: "A team that stands out",
        section5_1: "CEO, Hardware Designer",
        section5_2: "Administration",
        section5_3: "Software Developer",
        section5_4: "Electronic Technician",
        section5_5: "SOLUTION I.C.E.: HIGH-END ELECTRONIC DEVELOPMENT IN QUEBEC",

    },
    fr: {
        section1: "Solution I.C.E. répond à vos objectifs et élève vos projets aux plus hauts standards de qualité et d'efficacité de production.",
        section2: "MATÉRIEL – LOGICIEL – CONCEPTION MÉCANIQUE : des solutions complètes clés en main",
        section3_1: "SOLUTION I.C.E. - QUI SOMMES-NOUS ?",
        section3_2: "Chez Solution I.C.E., notre capacité d'innovation et d'adaptabilité nous permet de livrer des solutions adaptées à vos besoins spécifiques. Notre expertise approfondie dans divers domaines matériels et logiciels nous permet d'offrir un large éventail de produits et de services.",
        section3_3: "Des relations d'affaires durables",
        section3_4: "Nous privilégions les partenariats à long terme, en cherchant à bâtir des relations durables et harmonieuses avec nos clients. Nous mettons l'accent sur la création de valeur et de confiance qui dépassent les projets immédiats.",
        section3_5: "Partenariat gagnant-gagnant",
        section3_6: "Pour toute notre équipe, il est essentiel de favoriser un environnement d'affaires honnête et fondé sur la confiance. Nous croyons en la création de véritables partenariats gagnant-gagnant, profitables des deux côtés.",
        section3_7: "Fiabilité garantie",
        section3_8: "Nous sommes fiers d'honorer nos engagements. Nous garantissons une livraison dans les délais et veillons à ce que tous les objectifs convenus avec nos clients soient atteints avec précision.",
        section3_9: "Affronter la concurrence",
        section3_10: "Nous suivons une politique de prix totalement transparents et équitables. Nos services sont très compétitifs, assurant un excellent rapport qualité-prix pour nos clients tout en maintenant notre rentabilité.",
        section3_11: "Le personnel de SOLUTION I.C.E., des idées qui vont loin.",
        section3_12: "Contactez Solution I.C.E.",
        section4: "Une équipe qui se distingue",
        section5_1: "PDG, Concepteur électronique",
        section5_2: "Administration",
        section5_3: "Développeur logiciel",
        section5_4: "Technicien électronique",
        section5_5: "SOLUTION I.C.E. : DÉVELOPPEMENT ÉLECTRONIQUE HAUT DE GAMME AU QUÉBEC"
    }
}