import './ContactInfo.css'
import logo from '../../../assets/Logo-solution-ICE.jpg';
import {useTranslation} from "../../global/translationsTools";
import {lang} from "./ContactInfo_lang";

function ContactInfo() {
    const translations = useTranslation(lang);

    return (
        <div className='section7'>
            <img src={logo} className='section7-logo'></img>
            <div className='section7-1'>
                <div className='section7-1-row'>
                    <div>
                        <div className='section7-1-title sice-font'>{translations('title1')}</div>
                        <div className='section7-1-info sice-font'>450. 577. 3906</div>
                    </div>
                    <div className='section-7-1-tag sice-font'>{translations('desc')}</div>
                </div>
                <div>
                    <div className='section7-1-title sice-font'>{translations('title2')}</div>
                    <div className='section7-1-info sice-font'>Waterloo, QC</div>
                </div>
            </div>
        </div>
    );
}

export default ContactInfo;